@use 'spacing';
@use 'br24-common';
@use './FooterVariables';

$image-border-width: br24-common.pixel-to-rem(2px);

.wrapper {
	display: flex;
	flex-direction: column;
	gap: spacing.$s200;
	align-items: center;
	justify-content: center;
}

.scrollTopButton {
	cursor: pointer;

	display: flex;
	flex-direction: column;
	gap: spacing.$s200;
	align-items: center;
	justify-content: center;

	color: var(--text-consistent-od-01);

	background: none;
	border: none;

	& > span {
		white-space: nowrap;
	}

	@include br24-common.link-focus(--text-highlight-first);
}

.image {
	width: FooterVariables.$scroll-top-button-dimension;
	height: FooterVariables.$scroll-top-button-dimension;
	padding: (FooterVariables.$scroll-top-button-dimension - br24-common.pixel-to-rem(32px) - 2 * $image-border-width) / 2;

	color: var(--text-consistent-ol);

	background-color: var(--background-highlight-first);
	border: $image-border-width solid var(--background-icon-first);
	border-radius: 50%;

	transition: transform 300ms;

	&:hover,
	&:focus-visible {
		transform: scale(1.1);
	}
}
