@use 'spacing';
@use 'br24-common';

.section {
	padding-top: spacing.$s200;
	padding-bottom: spacing.$s200;
}

.titlePrimary {
	margin: 0 auto;
	padding-bottom: spacing.$s800;
}

.styledLink {
	text-decoration: underline;

	@include br24-common.link-focus;
}

.unorderedList {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: spacing.$s400;
	padding-right: spacing.$s600;
	padding-left: spacing.$s600;

	list-style: none;

	li {
		position: relative;
		margin-bottom: spacing.$s300;
		padding-left: spacing.$s500;

		&::before {
			content: ' ';

			position: absolute;
			top: 8px;
			left: 0;

			display: block;

			width: 6px;
			height: 6px;

			background-color: var(--background-icon-second);

			@media only screen and (width >= 48em) {
				top: 12px;
			}
		}
	}
}

.list {
	margin-top: spacing.$s400;
	padding-bottom: spacing.$s400;
}

.listItem {
	display: flex;
	align-items: center;
	margin-bottom: spacing.$s800;

	&:last-child {
		margin-bottom: 0;
	}
}
