.section {
	display: flex;
	flex-direction: column;
}

.appLink {
	&:focus-visible {
		img {
			outline: 3px double var(--text-highlight-first);
			outline-offset: 2px;
		}
	}
}
