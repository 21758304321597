.image {
	transition-timing-function: ease-in-out;
	transition-duration: 300ms;
	transition-property: transform, filter;
}

.imageZoomEnabled {
	&:hover,
	&:focus-visible {
		& .image {
			transform: scale(1.03);
			filter: brightness(110%);
		}
	}
}
