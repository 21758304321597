@use 'breakpoints';
@use 'spacing';
@use './HeaderVariables';

.navigationContainer {
	display: grid;
	grid-template-areas: 'logo title buttons';
	grid-template-columns: min-content 1fr min-content;

	height: HeaderVariables.$mobile-header-height;

	background-color: var(--background-default-third);

	transition: height 200ms;

	&.condensed {
		height: HeaderVariables.$desktop-header-condensed-height;
	}

	@media screen and (min-width: breakpoints.$lg) {
		grid-template-areas:
			'logo highlight buttons'
			'logo title title';
		height: HeaderVariables.$desktop-header-height;
	}
}

.logo {
	grid-area: logo;
	height: 100%;
}

.highlightNavigation {
	display: none;
	grid-area: highlight;
	min-width: 0;

	@media screen and (min-width: breakpoints.$lg) {
		display: flex;
		height: HeaderVariables.$mobile-header-height;
	}
}

.navigationButtons {
	display: flex;
	grid-area: buttons;
	flex-direction: row;
	justify-content: flex-end;

	height: HeaderVariables.$mobile-header-height;
	padding-right: spacing.$s100;
}

.title {
	overflow: hidden;
	grid-area: title;

	height: HeaderVariables.$mobile-header-height;

	line-height: HeaderVariables.$mobile-header-height;
	text-overflow: ellipsis;
	white-space: nowrap;

	transition-duration: 200ms;
	transition-property: height, color;

	@media screen and (min-width: breakpoints.$lg) {
		height: HeaderVariables.$desktop-header-height - HeaderVariables.$mobile-header-height;
		padding: 0 HeaderVariables.$highlight-navigation-padding-left;

		line-height: HeaderVariables.$desktop-header-height - HeaderVariables.$mobile-header-height;
		color: var(--text-consistent-ol);

		background-color: var(--background-highlight-first);

		.condensed & {
			height: HeaderVariables.$desktop-header-condensed-height - HeaderVariables.$mobile-header-height;
			color: var(--background-highlight-first);
		}
	}
}

.navigationButton {
	cursor: pointer;

	display: flex;
	align-items: center;
	align-self: flex-end;
	justify-content: center;

	height: 100%;
	padding: 0 spacing.$s300;

	color: var(--text-consistent-od-01);

	background-color: transparent;
	border: none;
	outline-offset: -3px;

	&:last-child {
		margin-right: 0;
	}

	& > * {
		display: inline-block;
		white-space: nowrap;
	}

	// don't change color of icon on hover
	.navigationIcon {
		color: var(--text-consistent-od-01);
	}

	&:focus-visible {
		color: var(--text-highlight-first);
		outline: 3px double var(--text-highlight-first);
	}

	@media screen and (min-width: breakpoints.$lg) {
		margin-right: spacing.$s1000 - (2 * spacing.$s300);
	}

	@include HeaderVariables.visited-link-color(--text-consistent-od-01, --text-highlight-first);
	@include HeaderVariables.highlight-on-hover;
}

.search {
	display: flex;
	@include HeaderVariables.highlight-on-hover(a);

	.menuOpen & {
		display: none;
	}

	@media screen and (min-width: breakpoints.$lg) {
		.menuOpen & {
			display: flex;
		}
	}
}

.buttonCaption {
	display: none;
	margin-right: spacing.$s300;

	.menuOpen & {
		display: inline-block;
	}

	@media screen and (min-width: breakpoints.$lg) {
		display: inline-block;
	}
}
