@use 'spacing';
@use './HeaderVariables';

.homeLink {
	display: flex;
	align-items: center;
	justify-content: center;   
	padding: 0 spacing.$s400;

	@include HeaderVariables.visited-link-color(--text-consistent-od-02, --text-highlight-first);

	&:focus-visible {
		outline-offset: -3px;
	}
}
