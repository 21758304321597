@use 'breakpoints';
@use 'spacing';
@use 'br24-common';
@use 'typo';

@use './FooterVariables';

.section {
	display: flex;
	flex-direction: column;
	background: var(--background-default-fifth);

	@media screen and (min-width: breakpoints.$lg) {
		flex-direction: row;
		gap: spacing.$s800;
		justify-content: space-between;
		padding-inline: spacing.$s800;
	}
}

.brLinks {
	display: flex;
	gap: spacing.$s1000;
	align-items: center;
	justify-content: center;

	padding-block: spacing.$s400;

	& > * {
		height: br24-common.pixel-to-rem(48px);
	}

	@media screen and (min-width: breakpoints.$sm) {
		gap: spacing.$s1500;
	}

	@media screen and (min-width: breakpoints.$lg) {
		gap: spacing.$s800;
	}
}

.iconLink {
	@include br24-common.link-focus(--text-highlight-first);
	@include br24-common.visited-link-color(--icon-consistent-od-01, --text-highlight-first);
	@include br24-common.highlight-on-hover('a', --icon-highlight);
}

.impressum {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;

	padding: spacing.$s400 spacing.$s800;

	background-color: var(--background-consistent-first);

	.link {
		cursor: pointer;

		display: flex;
		justify-content: space-between;

		width: 100%;
		padding: spacing.$s400;

		background-color: unset;
		border: none;

		@include typo.heading3;

		@include br24-common.link-focus(--text-highlight-first);

		&,
		&:hover {
			color: var(--text-consistent-od-01);
		}

		@include FooterVariables.visited-link-color(--text-consistent-od-01);
		@include FooterVariables.highlight-on-hover;

		.icon {
			flex-shrink: 0;
			color: var(--text-consistent-od-01);

			@media screen and (min-width: breakpoints.$lg) {
				display: none;
			}
		}

		@media screen and (min-width: breakpoints.$lg) {
			display: unset;
			padding: 0;
			white-space: nowrap;

			@include typo.heading4;
		}
	}

	@media screen and (min-width: breakpoints.$lg) {
		flex-direction: row;
		align-items: center;
		padding: 0;
		background-color: unset;
	}
}

.linkTitle {
	overflow: hidden;
	padding-right: spacing.$s100;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.divider {
	display: none;
	padding-inline: spacing.$s400;
	@media screen and (min-width: breakpoints.$lg) {
		display: block;
	}
}
