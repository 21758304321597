@use 'br24-common';
@use 'spacing';

.chevron {
	flex-shrink: 0;
}

.linkWithChevron {
	cursor: pointer;

	display: flex;
	flex-direction: row;
	gap: spacing.$s400;
	align-items: center;

	color: var(--text-default-first);

	@include br24-common.link-focus;

	&:hover {
		text-decoration: underline;
	}

	&:visited {
		color: var(--text-default-first);
	}
}
