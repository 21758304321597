@use 'spacing';
@use 'br24-common';

.themeItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: spacing.$s200;

	& > label {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		gap: spacing.$s200;
		align-items: center;
		justify-content: space-between;

		@include br24-common.link-focus;
	}

	& input[type='radio'] {
		position: absolute;
		opacity: 0;
	}

	& .selected {
		color: var(--text-consistent-od-01);
	}
}

.checkbox {
	flex-shrink: 0;
}
