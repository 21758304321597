@use '../FooterVariables';
@use 'spacing';
@use 'br24-common';

.link {
	display: block;
	padding-top: spacing.$s200;

	@include br24-common.visited-link-color(--icon-consistent-od-02, --text-highlight-first);
	@include FooterVariables.highlight-on-hover(a);
	@include br24-common.link-focus(--text-highlight-first);
}
