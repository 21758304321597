@use '../scss/theme';
@use '../scss/global-typography';
@use 'br24-common';

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;

	-webkit-font-smoothing: antialiased;
	text-rendering: optimizelegibility;
	word-break: break-word;

	@include br24-common.link-focus;

	&:focus:not(:focus-visible) {
		outline: none;
	}

	@media screen and (prefers-reduced-motion) {
		transition: none !important;
	}
}

#__next {
	display: flex;
	flex-direction: column;

	width: 100%;
	min-width: 320px;
	max-width: 1536px;
	min-height: 100vh;

	@supports (min-height: 100dvh) {
		min-height: 100dvh;
	}
}

.srOnly {
	position: absolute;
	top: auto;
	left: -10000px;

	overflow: hidden;

	width: 1px;
	height: 1px;
}

html {
	text-size-adjust: 100%;
}

html,
body {
	margin: 0;
	padding: 0;
	background-color: var(--background-default-second);
}

body {
	position: relative;

	display: flex;
	justify-content: center;

	box-sizing: border-box;
	width: 100%;
}

main {
	display: block;

	&:focus {
		outline: none;
	}
}

small {
	font-size: 0.8rem;
}

img {
	border-style: none;
}

hr {
	overflow: visible;
	box-sizing: content-box;
	height: 0;
}

abbr[title] {
	text-decoration: underline dotted;
	border-bottom: 0;
}

pre {
	font-family: monospace;
	white-space: normal;
}

video {
	display: block;
}

b,
strong {
	font-weight: 600;
}

address {
	font-style: normal;
}

em {
	font-size: 1.1rem;
	font-style: italic;
}

code,
kbd,
samp {
	font-family: monospace;
}

u {
	font-size: 1.1rem;
	text-decoration: underline;
}

s {
	font-size: 1.1rem;
	text-decoration: line-through;
}

figure {
	margin: 0;
}

/* Inherit fonts for inputs and buttons */
button,
input,
optgroup,
select,
textarea {
	margin: 0;
	font: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

legend {
	display: table;

	box-sizing: border-box;
	max-width: 100%;
	padding: 0;

	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

textarea {
	overflow: auto;
}

[type='checkbox'],
[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	appearance: textfield;
	outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
	appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	appearance: button;
}

details {
	display: block;
}

summary {
	display: list-item;
}

template {
	position: absolute;
	height: 0;
	visibility: hidden;
}

[hidden] {
	position: absolute;

	overflow: hidden;

	width: 1px;
	height: 1px;

	white-space: nowrap;

	clip: rect(0 0 0 0);
	clip-path: inset(50%);
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
	outline: 1px dotted ButtonText;
}

sub,
sup {
	position: relative;
	font-size: 0.75rem;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

blockquote {
	font-weight: 400;
	line-height: 1.75;
	color: var(--text-default-first);
}

a {
	color: var(--text-default-first);
	text-decoration: none;

	&:visited {
		color: var(--text-default-first);
		outline: none;
	}

	&:focus,
	&:hover,
	&:visited:hover {
		color: var(--text-highlight-second);
		outline: none;
	}
}
