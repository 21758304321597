@use 'spacing';
@use 'br24-common';

.themeSelector {
	display: flex;
	gap: spacing.$s700;
	align-items: flex-start;
	align-self: stretch;
	justify-content: center;

	padding: spacing.$s400 0;

	list-style: none;
}

.themeItem {
	display: flex;
	flex-direction: column;
	align-items: center;

	& > label {
		display: flex;
		flex-direction: column;
		align-items: center;

		&:focus-visible {
			outline: 3px double currentColor;
		}
	}

	& input[type='radio'] {
		position: absolute;
		opacity: 0;
	}
}

.radioImage {
	margin-bottom: spacing.$s600;
	border-radius: 4px;
	outline: 1px solid #647890;

	.selected & {
		outline-color: var(--icon-highlight);
		outline-width: 4px;
	}
}

.checkbox {
	margin-bottom: spacing.$s100;
}

.radioLabel {
	// setting the height to a fixed value, to account for different line heights of
	// selected vs unselected radio boxes
	line-height: br24-common.pixel-to-rem(28px);
}
