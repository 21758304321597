@use 'spacing';
@use '../HeaderVariables';

.navigationListItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	justify-content: center;

	background-color: var(--background-default-fifth);
	border-bottom: solid 2px var(--background-button-second);

	&:last-of-type {
		border-bottom: none;
	}

	.button {
		width: 100%;
		color: var(--text-consistent-od-01);

		@include HeaderVariables.visited-link-color;
		@include HeaderVariables.highlight-on-hover;

		// this is not ideal as this knows about the internal structure of the navigation button
		& > svg {
			color: var(--text-consistent-od-01);
		}

		&:focus-visible {
			color: var(--text-highlight-first);
			outline: 3px double var(--text-highlight-first);
			outline-offset: -3px;
		}
	}

	.link {
		overflow: hidden;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		width: 100%;
		height: HeaderVariables.$main-navigation-item-height;
		padding: 0 spacing.$s400;

		text-overflow: ellipsis;
		white-space: nowrap;

		outline-offset: -3px;

		&,
		&:visited {
			color: var(--text-consistent-od-01);
		}

		&:focus-within:focus-visible {
			color: var(--text-highlight-first);
			outline: 3px double var(--text-highlight-first);
		}

		@include HeaderVariables.visited-link-color;
		@include HeaderVariables.highlight-on-hover;

		&.active {
			color: var(--text-highlight);
		}
	}

	.icon {
		margin-left: auto;

		// do not change icon color on hover
		color: var(--text-consistent-od-01);
	}

	.subpointIcon {
		height: spacing.$s600;
		padding-right: spacing.$s100;

		// do not change icon color on hover
		color: var(--text-consistent-od-01);
	}

	@for $i from 1 to 2 {
		&.depth#{$i} {
			padding-left: max($i - 1, 0) * spacing.$s600;
			background-color: var(--background-default-third);
		}
	}
}

.nestedNavigationList {
	display: none;
	width: 100%;
	background-color: var(--background-background-third);

	.expanded & {
		display: block;
	}
}
