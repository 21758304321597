@use 'spacing';
@use './HeaderVariables';

.highlightNavigation {
	overflow: hidden;

	// this will hide overflowing navigation nodes
	flex-wrap: wrap;
	gap: spacing.$s800;
	align-items: center;

	padding-left: HeaderVariables.$highlight-navigation-padding-left;

	color: var(--text-consistent-od-02);
}

.link {
	height: 100%;
	line-height: HeaderVariables.$mobile-header-height;
	white-space: nowrap;

	&.active {
		color: var(--text-highlight-first);

		@at-root a:visited#{&},
			a#{&} {
			color: var(--text-highlight-first);
		}
	}

	&.hidden {
		visibility: hidden;
	}

	&:focus-visible {
		color: var(--text-highlight-first);
	}

	@include HeaderVariables.visited-link-color(--text-consistent-od-02, --text-highlight-first);

	// the highlight on hover specification needs to be after the visited state
	// because otherwise the specificity of the highlight definition is lower
	@include HeaderVariables.highlight-on-hover(a);
}

.button {
	cursor: pointer;

	color: var(--text-consistent-od-02);
	white-space: nowrap;

	background: transparent;
	border: none;

	@include HeaderVariables.highlight-on-hover;

	@include HeaderVariables.visited-link-color(--text-consistent-od-02, --text-highlight-first);
	
	&:focus-visible {
		color: var(--text-highlight-first);
		outline: 3px double var(--text-highlight-first);
	}
}
